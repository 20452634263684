import TagInput from "common/components/shared/TagInput";
import { Button } from "common/components/ui/Button";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaRegUserCircle } from "react-icons/fa";
import { RiArrowRightSLine } from "react-icons/ri";
import { TbLoader } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { apiClient } from "utils";

export default function Emails() {
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState("");
  const icons = [
    {
      name: "Anchor",
      url: "https://res.cloudinary.com/djgamcnfy/image/upload/v1737380177/27prediction/AnchorIcon_fig8z2.png",
    },
    {
      name: "Mic",
      url: "https://res.cloudinary.com/djgamcnfy/image/upload/v1737380177/27prediction/MicIcon_ltdume.png",
    },
    {
      name: "Book",
      url: "https://res.cloudinary.com/djgamcnfy/image/upload/v1737380177/27prediction/BookIcon_hawtur.png",
    },
  ];
  const [selectedIcon, setSelectedIcon] = useState<any>(null);

  const [setting, setSetting] = useState<any>({ template: "match" });
  const [tags, setTags] = useState<string[]>([]);

  const [sending_email, set_sending_email] = useState(false);

  const sendEmail = async () => {
    try {
      if (sending_email) return;
      const _local_storage = localStorage.getItem("credential");
      if (!_local_storage || !JSON.parse(_local_storage || "").accessToken) {
        toast.error("UNAUTHORIZED");
        return;
      }
      set_sending_email(true);
      const response = await apiClient.post(
        "/send-email",
        {
          ...setting,
          ...(setting.template === "update" ? { title: titleValue, tags } : {}),
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(_local_storage || "").accessToken
            }`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Email sent successfully");
        setSetting({});
        setTags([]);
        setTitleValue("");
      }
      set_sending_email(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "Something went wrong");
      set_sending_email(false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto">
      <header className="border-b p-4 border-border/30 h-[74px] flex items-center gap-3">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center h-full gap-3 text-muted-foreground"
        >
          <FaRegUserCircle className="size-4" />
          <p>Emails</p>
        </button>
        <div className="flex items-center h-full gap-3 text-muted-foreground">
          <RiArrowRightSLine className="size-4" />
          <p>Email Templates</p>
        </div>
      </header>

      <div className="mt-8 p-4">
        <h5 className="flex items-end gap-2">Publish</h5>
      </div>
      <div className="p-4">
        <div className="flex flex-col border border-border/30 rounded-2xl overflow-hidden">
          <div className="flex gap-44 items-center [background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] px-4 py-3">
            <form className="w-1/2">
              <div className="flex flex-col gap-4 col-span-">
                <label htmlFor="telmpate" className="text-sm">
                  Template
                </label>
                <select
                  onChange={(e) => {
                    setSetting({
                      ...setting,
                      template: e.target.value.trim(),
                    });
                  }}
                  value={setting.template}
                  name=""
                  id="template"
                  className="[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] border border-border/30 appearance-none px-10 outline-none"
                >
                  <option value="match">Upcoming Matches</option>
                  <option value="round" className="">
                    Upcoming Round
                  </option>
                  <option value="update">Platform Update</option>
                </select>
              </div>

              {setting?.template === "update" ? (
                <div className="mt-10 w-full">
                  <input
                    onChange={(e) => {
                      setTitleValue(e.target.value);
                    }}
                    value={titleValue}
                    placeholder="title"
                    type="text"
                    className="size-full border border-border/30 py-2 outline-none rounded-lg text-base px-5 pr-10 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground"
                  />

                  <div className="mt-5 w-full">
                    <TagInput
                      icons={icons}
                      selectedIcon={selectedIcon}
                      setSelectedIcon={setSelectedIcon}
                      setTags={setTags}
                      tags={tags}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mt-10">
                <h5 className="flex items-end gap-2">Publish to</h5>
                <small className="text-[#757874]">
                  Select the recipient's for this mail
                </small>

                <div className="flex items-center mt-5 gap-36">
                  <div className="flex items-center gap-2">
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSetting({
                            ...setting,
                            target: "all",
                          });
                          return;
                        }
                        setSetting({ ...setting, target: undefined });
                      }}
                      checked={setting.target === "all"}
                      className="border-none outline-none"
                      type="checkbox"
                      name=""
                      id=""
                    />
                    <p className="text-white text-sm">All Players</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSetting({
                            ...setting,
                            target: "selected",
                          });
                          return;
                        }
                        setSetting({ ...setting, target: undefined });
                      }}
                      checked={setting.target === "selected"}
                      className="border-none outline-none"
                      type="checkbox"
                      name=""
                      id=""
                    />
                    <p className="text-white text-sm">Selected Players</p>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <h5 className="flex items-end gap-2">Scheduling</h5>
                <small className="text-[#757874]">
                  Select when this mail should be sent
                </small>

                <div className="flex items-center mt-5 gap-36">
                  <div className="flex items-center gap-2">
                    <input
                      className="border-none outline-none"
                      type="checkbox"
                      name=""
                      id=""
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSetting({
                            ...setting,
                            schedule: "immediate",
                            scheduled_time: undefined,
                          });
                          return;
                        }
                        setSetting({ ...setting, schedule: undefined });
                      }}
                      checked={setting.schedule === "immediate"}
                    />
                    <p className="text-white text-sm">Immediate Send</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      className="border-none outline-none"
                      type="checkbox"
                      name=""
                      id=""
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSetting({
                            ...setting,
                            schedule: "time",
                          });
                          return;
                        }
                        setSetting({ ...setting, schedule: undefined });
                      }}
                      checked={setting.schedule === "time"}
                    />
                    <p className="text-white text-sm">Scheduled Send</p>
                    {setting.schedule === "time" ? (
                      <input
                        onChange={(e) => {
                          if (e.target?.value) {
                            setSetting({
                              ...setting,
                              scheduled_time: new Date(
                                e.target.value
                              ).toISOString(),
                            });
                          } else {
                            setSetting({
                              ...setting,
                              scheduled_time: undefined,
                            });
                          }
                        }}
                        type="datetime-local"
                        className="[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] border border-border/30 outline-none ml-10"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </form>

            <div className="-mt-32">
              {selectedIcon ? (
                <img src={selectedIcon?.url} className="w-64 h-64" alt="" />
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex gap-4 mt-10 items-center justify-end">
          <Button
            className=""
            onClick={() => {
              setSetting({});
            }}
            variant={"secondary"}
          >
            Cancel
          </Button>
          <Button
            className="px-20"
            onClick={() => {
              if (!setting.template) {
                toast.error("select template");
                return;
              }
              if (setting.template === "update") {
                if (titleValue.trim() === "" || tags.length === 0) {
                  toast.error("Title and messages required");
                  return;
                }
              }
              if (!setting.target) {
                toast.error("select target");
                return;
              }
              if (!setting.schedule) {
                toast.error("select schedule");
                return;
              }

              if (setting.schedule === "time" && !setting.scheduled_time) {
                toast.error("select schedule time");
                return;
              }

              if (setting.target !== "all") {
                if (setting.template === "update") {
                  navigate("/players", {
                    state: {
                      settings: { ...setting, title: titleValue, tags },
                    },
                  });
                  return;
                }
                navigate("/players", { state: { settings: setting } });
                return;
              }

              sendEmail();
            }}
            variant={"default"}
          >
            {sending_email ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                Publishing...
              </>
            ) : (
              "Publish Email"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
