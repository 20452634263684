import { useState } from "react";
import { X } from "lucide-react";
import toast from "react-hot-toast";

interface Props {
  tags: any[];
  icons: any[];
  selectedIcon: any;
  setSelectedIcon: (icon: any) => void;
  setTags: (newTags: any[]) => void;
}
const TagInput = ({
  setTags,
  tags,
  selectedIcon,
  setSelectedIcon,
  icons,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // console.log(event.key);
    if (event.key === "Enter" && !event.shiftKey && inputValue.trim() !== "") {
      if (tags.length === 3) {
        toast.error("Limit reached");
        return;
      }
      event.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        if (!selectedIcon) {
          toast.error("Icon required");
          return;
        }
        setTags([...tags, { icon: selectedIcon, message: inputValue.trim() }]);
      }
      setInputValue("");
      setSelectedIcon(null);
    }
  };

  const removeTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <div className="  space-y-2 border p-2 rounded-lg">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="flex items-center justify-between border bg-custom-gradient text-muted-foreground px-3 py-1 rounded-xl text-sm"
        >
          <span className="whitespace-pre-wrap break-words">{tag.message}</span>

          <button
            onClick={() => removeTag(index)}
            className="ml-2 focus:outline-none"
          >
            <X size={16} className="text-white" />
          </button>
        </div>
      ))}
      <div className="flex flex-col gap-1 col-span-">
        <label htmlFor="telmpate" className="text-sm">
          Icon
        </label>
        <select
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              return;
            }
            setSelectedIcon(JSON.parse(e.target.value));
          }}
          value={!selectedIcon ? "" : JSON.stringify(selectedIcon)}
          name=""
          id="template"
          className="[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] border border-border/30 appearance-none px-10 outline-none"
        >
          <option value="">Select Icon</option>
          {icons.map((icon) => {
            return <option value={JSON.stringify(icon)}>{icon.name}</option>;
          })}
        </select>
      </div>
      <textarea
        className="
          outline-none rounded-md w-full text-sm bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed
          flex-grow p-2 resize-none"
        placeholder="Add a message..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={addTag}
        rows={2}
      />
    </div>
  );
};

export default TagInput;
